* {
  font-size: 15px;
  font-family: 'Noto Sans JP', sans-serif !important;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

html, body {
  overflow-x: hidden;
}

.header {
  display: flex;
  margin: 0 auto;
  border-bottom: 1px solid #f2f2f2;
  padding: 8px 0;
  position: fixed;
  width: 100%;
  top: 0;
  background-color: #1f1f1f;
  box-shadow: -4px 8px 23px -3px;
  z-index: 3;
}

.header-topics {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.header-topics > p {
  padding-top: 10px;
}
.header-topics > p > a{
  color: #fff;
  text-decoration: none;
}

.header-topics .facebook-img > img {
  width: 20px;
  padding-top: 14px;
}

.header .header-topics > p > a:hover {
  color: red !important;
}

.mobile-header-menu {
  display: none;
  position: relative;
  width: 100%;
}

.mobile-header-menu > .hamburguer-menu {
  position: absolute;
  right: 15px;
  top: 11px;
  width: 25px;
}

.mobile-menu {
  position: absolute;
  background: white;
  padding: 10px 25px;
  top: 45px;
  right: 25px;
  border-radius: 5px;
  box-shadow: -4px 8px 31px 5px;
  min-width: 150px;
}

.mobile-menu a {
  text-decoration: none;
  color: #3d3d3d;
}

.mobile-menu > a, .mobile-menu > p {
  margin: 15px 0;
} 

.face {
  padding-left: 50px;
  padding-top: 15px;
  text-decoration: none;
  color: #fff;
}

.logo {
  width: 150px;
  height: 40px;
  padding-left: 15px;
}

.container {
  margin-top: 63px;
}

.center {
  /* padding-top: 50px; */
  /* max-width: 900px; */
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  color: #3d3d3d !important;
}

.center .slider-img {
  /* border-radius: 15px; */
  /* width: 900px; */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 500px;
}

.text {
  padding-top: 15px;
  height: 135px;
  width: 500px;
  margin: 0 auto;
  color: #3d3d3d;
}

.text p {
  margin-left: 15px;
}

.about {
  display: flex;
  flex-direction: column;
  width: 800px;
  margin: 0 auto;
  margin-top: 15px;
  width: 100%;
}

.about .about-img {
  /* width: 800px; */
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat; 
  width: 100%; 
  height: 500px
  
}

.about h1 {
  font-size: 40px;
  /* padding-left: 150px; */
  padding-top: 10px;
  color: #3d3d3d;
  margin: auto;
}

.about p {
  color: #3d3d3d;
  /* padding-left: 150px; */
  margin: auto;
  text-align: center;
}

.info {
  padding-top: 50px;
  display: flex;
  flex-direction: row;
  color: #3d3d3d;
  justify-content: space-around;
}

.info .card {
    border: 1px solid #b3adad;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 30px;
    border-radius: 10px;
    cursor: pointer;
    transform: translateZ(0);
    transition: transform 0.25s ease-out;
    text-decoration: none;
    color: #3d3d3d;
    margin: 5px;
}
.info .card:hover {
  transform: scale(1.05);
}

 .center a {
  border: 1px solid #fff000;
  border-radius: 3px;
  padding: 10px;
  text-decoration: none;
  color: #fff000;
  top: 10px;
  align-items: center;
  transition: 0.4s;
}

 .center a:hover {
  border: 1px solid #fff000;
  text-decoration: none;
  color: #2b2b2b;
  background: #fff000;
  opacity: 0.9;
}

.info .title {
  font-size: 24px;
  margin: 0 auto;
}

.info p {
  padding-bottom: 15px;
}

.info img {
  margin: 0 auto;
  width: 200px;
  height: 150px;
  padding-right: 5px;
  border-radius: 15px;
}

.info .infos {
  width: 150px;
  margin: 0 auto;
  display: flex;
    flex-direction: column;
}

#contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
}

#contact .title {
  font-size: 40px;
  color: #3d3d3d;
  padding-bottom: 10px;
}

#contact .contact-infos-container {
 display: flex;
 align-items: center;
}

#contact .contact-infos-container > p, #contact .contact-infos-container a {
  color: #3d3d3d;
  margin: 0 5px;
  font-size: 20px;
}

#contact .contact-infos-container > p > img, #contact .contact-infos-container > p > a > img {
  width: 20px;
}

.location {
  padding-top: 60px;
  width: 800px;
  margin: 0 auto;
}

.location p {
  font-size: 40px;
  color: #3d3d3d;
  padding-bottom: 10px;
}

.footer {
  margin-top: 40px;
  padding-top: 10px;
  border-top: 1px solid #fff;
  color: #3d3d3d;
  border-top: 1px solid #3d3d3d;
  display: flex;
  flex-direction: row;
}

.scubaSchool {
  margin: 0 auto;
}

.footer .sdi {
  width: 100px;
  height: 50px;
}

.footer p {
  margin: 0 auto;
  overflow-x: hidden;
  padding-top: 15px;
}

.back  {
  margin: 15px;
  position: absolute;
  top: 70px;
}
.subtitle {
  cursor: pointer;
}

.content, .places, .inside {
  width: 900px;
  margin: 75px auto;
  color: #3d3d3d;
}

.content img {
  width: 900px;
  height: 500px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
  border-radius: 15px;
}

.inside .list li {
  display: inline;
  font-size: 14px;
  border-radius: 3px;
}

.inside .list {
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 10px;
}

.content p {
  width: auto;
  color: #3d3d3d;
  margin: 0 auto;
}

.content .title {
  font-size: 32px;
}

.content .price {
  font-size: 20px;
}

.inside img {
  width: 900px;
  height: 500px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
  border-radius: 15px;
}

.warning {
  font-size: 12px;
  padding-bottom: 10px;
}

.inside p {
  width: auto;
  color: #3d3d3d;
  margin: 0 auto;
}

.inside .title {
  font-size: 32px;
}

.inside .subtitle, .places .subtitle {
  font-size: 20px;
  padding: 15px;
}

.inside .price {
  font-size: 20px;
}

.places .places-img {
  /* width: 900px;
  height: 500px; */
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 15px;
  margin-bottom: 10px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 600px;
}

.places p {
  width: auto;
  color: #3d3d3d;
  margin: 0 auto;
}

.places .title {
  font-size: 32px;
}

.places .price {
  font-size: 14px;
}
.places .only {
  padding-bottom: 15px;
}

.contact {
  display: none;
}

.contact-elem {
  text-decoration: none;
  color: #ffff;
}

.center {
  text-align: center;
  color: #fff;
}

.center h1 {
  font-weight: bold;
    font-size: 55px;
}

.center a {
  width: 22%;
  margin: auto;
}

@media(max-width:640px) {
  .html {
    overflow-x: hidden;
  }
  .header-topics {
    display: none;
  }
  .center {
    overflow-x: hidden;
    /* padding-top: 15px; */
    height: 700px;
  }
  .logo {
    padding-left: 25px;
  }
  .center .slider-img {
    height: 300px;
  }
  .face {
    display: none;
  }
  .text {
    width: 250px;
    height: 161px;
  }
  .text p {
    font-size: 16px;
  }
  .about .about-img {
    height: 350px
  }
  .about {
    margin-top: 10px;
    /* width: 265px; */
    overflow-x: hidden;
  }
  .about p {
    padding-left: 0;
  }
  .about h1 {
    font-size: 14px;
    padding-left: 0;
    padding-top: 5px;
    padding-bottom: 10px;
  }
  .info {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .info img {
    margin-top: 10px;
  }
  .infos .title {
    font-size: 14px;
  }
  .infos {
    height: 200px;
  }
  .location {
    padding-top: 40px;
    width: 90%;
    margin: 0 auto;
  }
  .location iframe {
    width: 100%;
    height: 50vh;
  }
  .contact, .face {
    color: #FFF;
    width: 250px;
    margin: 0 auto;
  }
  .contact .fab {
    color: #FFF;
  }
  .footer {
    width: auto;
    display: flex;
    flex-direction: column;
  }
  .text {
    bottom: 30px;
  }
  .content, .inside, .places {
    width: 305px;
  }
  .content img, .inside img, .places .places-img {
    width: 305px;
    height: 205px;
  }
  .contact {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 1024px) {
  .center {
    min-height: 750px;
  }
}

@media (min-width: 641px) and (max-width: 1023px) {
  
  .center {
    overflow-x: hidden;
    /* padding-top: 15px; */
    height: 680px;
  }
  .logo {
    padding-left: 25px;
  }
  .center .slider-img {
    height: 400px;
  }
  .face {
    display: none;
  }
  .text {
    height: 161px;
  }
  .text p {
    font-size: 16px;
  }
  .about .about-img {
    height: 350px;
  }
  .about {
    margin-top: 10px;
    width: 100%;
    overflow-x: hidden;
  }
  .about p {
    padding-left: 0;
  }
  .about h1 {
    font-size: 14px;
    padding-left: 0;
    padding-top: 5px;
  }
  .info {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .info img {
    margin-top: 10px;
    width: 281px;
    height: 182px;
  }
  .infos .title {
    font-size: 14px;
  }
  .infos {
    height: 200px;
  }
  .location {
    padding-top: 40px;
    width: 90%;
    margin: 0 auto;
  }
  .location iframe {
    width: 100%;
    height: 25em;
  }
  .contact {
    color: #FFF;
    width: 250px;
    margin: 0 auto;
  }
  .contact .fab {
    color: #FFF;
  }
  .footer {
    padding-left: 35px;
  }
  .text {
    bottom: 30px;
  }
  .content, .inside, .places {
    width: 100%;
  }
  .content p, .inside p, .places p {
    width: 85%;
  }
  .content img, .inside img, .places img {
    width: 90%;
    display: flex;
  }
  .contact {
    display: block;
  }
}

@media(min-width: 769px) {
  .header .header-topics {
    justify-content: space-around;
  }
}

@media(max-width: 745px) {
  .header .header-topics { 
    display: none;
  }

  .mobile-header-menu {
    display: block;
  }

  #contact .contact-infos-container {
    flex-direction: column;
  }
}